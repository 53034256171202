@BASE_PATH: '';
// 解决announcement 不能第一屏挂载style height，导致公告多条展示
.slick-list{
  height: 58px
}
body{
    #interfuse_header{
      .header-theme-dark{
        max-width: 1332px;
        margin: 0 auto;
      }
    }
    #interfuse_footer{
      display: none;
    }
}